import { Link } from "gatsby"
import React from "react"
import FAQ from "../components/FAQ/FAQ"
import Hero from "../components/Hero/Hero"
import IconBlock from "../components/About/IconBlock"
import TextBlock from "../components/About/TextBlock"
import TheTeam from "../components/About/TheTeam"
import Layout from "../components/layout"
import { SEO } from "../components/seo"
import ServiceCTA from "../components/ServiceLanding/CTA/ServiceCTA"
import PartnerShip from "../components/PartnerShip"
import faqs from '../data/faq.json'
function About({ location }) {
    const heroData = {
        title: "Hello! We’re Saigon Digital.",
        description:
            "Solving digital challenges with forward-thinking, user-centric and bespoke solutions.",
        sub: ""
    }
    const context = {
        pageName: "SD | About",
        pageUri: location.href
    }
    return (
        <Layout context={context}>
            <Hero props={heroData} />
            <section className="bg-[#3165F5]/[0.1] py-20">
                <div className="container">
                    <TextBlock
                        preHeader="about us"
                        header="Who are we?"
                        text={[
                            "As a full-service digital agency, we specialise in Web UI/UX & Web Development using the JAMstack to build powerful digital platforms, we help build brands that get remembered, and offer marketing strategy & solutions that make an impact.",
                            "Our mission is more than delivering services; it's about creating long lasting partnerships to accelerate your business growth and elevate your brand to new heights."
                        ]}
                    />
                </div>
            </section>
            <section className="py-20">
                <TheTeam />
            </section>
            <section className="pb-10 lg:pb-20">
                <ServiceCTA
                    title={
                        "Want to join Saigon Digital? We're always on the look out for new talent to join our team."
                    }
                    ctaText={"Apply now"}
                />
            </section>
            <section>
                <IconBlock />
            </section>

            <PartnerShip
                title={"Our Partnerships"}
                description={
                    "Saigon Digital is honoured to partner with leading tech innovators such as Gatsby and Netlify, along with the top-tier CMS providers like Sanity and Prismic. \n \n These strategic partnerships allow us to stay on the cutting edge of technology, ensuring that our clients benefit from the latest advances and best practices. Together, we're shaping the future of the digital landscape."
                }
            />

            <section className="pb-10 pt-16 lg:pb-20 lg:pt-24">
                <div className="container">
                    <TextBlock
                        preHeader="FREQUENTLY ASKED QUESTIONS"
                        header="Working with  Saigon Digital"
                        text={[
                            "We know that finding the right agency partner can be difficult and time consuming. So we hope that by being transparent with the information below, we can provide some useful guidance that will save you some time in helping to establish if we might be a good fit for you or your project."
                        ]}
                    >
                        <p className="mt-4 text-center sm:mt-8">
                            If you’re uncertain of anything, feel free to{" "}
                            <Link
                                className="font-bold text-[#3165F5] underline"
                                to={"/contact"}
                            >
                                {" "}
                                drop us a message
                            </Link>
                            – we’re always happy to talk.
                        </p>
                    </TextBlock>
                </div>
            </section>

            <section className="container">
                <div className="lg:px-40">
                    <FAQ faqs={faqs.aboutUsFaqs}/>
                </div>
            </section>
        </Layout>
    )
}
export default About

export const Head = () => (
    <SEO
        title={"About | Jamstack Development Agency | Saigon Digital"}
        description={"Web Design and Development in Saigon, Vietnam"}
        keywords={
            "web design saigon, web development saigon, saigon digital agency, jamstack development, jamstack web development saigon"
        }
    />
)
